import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`My Journey`}</h3>
    <p>{`I grew up as a math nerd in `}<a parentName="p" {...{
        "href": "https://www.pinterest.com/vha14/ha-noi-near-the-opera-house-nha-hat-lon/"
      }}>{`Hanoi`}</a>{`,
Vietnam, claiming a `}<a parentName="p" {...{
        "href": "https://www.imo-official.org/participant_r.aspx?id=11018"
      }}>{`gold medal`}</a>{` at IMO 1986. As a
CS major in Hungary, I wandered around the Eastern Bloc as the Berlin wall fell. Moving further west to
Milwaukee, Wisconsin, I got a `}<a parentName="p" {...{
        "href": "https://www.semanticscholar.org/paper/Similarity-of-personal-preferences-Theoretical-fou-Ha-Haddawy/8dda02af0be09deb7fd5e540608f20f4de69430c"
      }}>{`PhD`}</a>{`
in AI, publishing `}<a parentName="p" {...{
        "href": "https://www.semanticscholar.org/author/Vu-A.-Ha/4480314"
      }}>{`a few papers`}</a>{`. After a stint at
Honeywell Labs, I moved even further west to Seattle, joining Bing in its `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Microsoft_adCenter_Labs"
      }}>{`formative years`}</a>{`.
In 2014 I joined the `}<a parentName="p" {...{
        "href": "https://allenai.org"
      }}>{`Allen Institute for AI (AI2)`}</a>{` to create
`}<a parentName="p" {...{
        "href": "https://semanticscholar.org"
      }}>{`Semantic Scholar`}</a>{`. Currently I am advising startups on real-world AI as part of
the institute's `}<a parentName="p" {...{
        "href": "http://www.ai2incubator.com"
      }}>{`incubator program`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      